exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-history-index-js": () => import("./../../../src/pages/history/index.js" /* webpackChunkName: "component---src-pages-history-index-js" */),
  "component---src-pages-history-part-1-what-are-enigmata-js": () => import("./../../../src/pages/history/part-1-what-are-enigmata.js" /* webpackChunkName: "component---src-pages-history-part-1-what-are-enigmata-js" */),
  "component---src-pages-history-part-2-symphosius-and-the-anglo-saxons-js": () => import("./../../../src/pages/history/part-2-symphosius-and-the-anglo-saxons.js" /* webpackChunkName: "component---src-pages-history-part-2-symphosius-and-the-anglo-saxons-js" */),
  "component---src-pages-history-part-3-rhyme-and-relatability-js": () => import("./../../../src/pages/history/part-3-rhyme-and-relatability.js" /* webpackChunkName: "component---src-pages-history-part-3-rhyme-and-relatability-js" */),
  "component---src-pages-history-part-4-is-this-a-riddle-i-see-before-me-js": () => import("./../../../src/pages/history/part-4-is-this-a-riddle-i-see-before-me.js" /* webpackChunkName: "component---src-pages-history-part-4-is-this-a-riddle-i-see-before-me-js" */),
  "component---src-pages-history-part-5-dismantling-the-words-js": () => import("./../../../src/pages/history/part-5-dismantling-the-words.js" /* webpackChunkName: "component---src-pages-history-part-5-dismantling-the-words-js" */),
  "component---src-pages-history-part-6-a-place-for-enigmata-js": () => import("./../../../src/pages/history/part-6-a-place-for-enigmata.js" /* webpackChunkName: "component---src-pages-history-part-6-a-place-for-enigmata-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-options-js": () => import("./../../../src/pages/options.js" /* webpackChunkName: "component---src-pages-options-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-riddles-dude-js": () => import("./../../../src/pages/riddles/dude.js" /* webpackChunkName: "component---src-pages-riddles-dude-js" */),
  "component---src-pages-riddles-index-js": () => import("./../../../src/pages/riddles/index.js" /* webpackChunkName: "component---src-pages-riddles-index-js" */),
  "component---src-pages-riddles-seuss-js": () => import("./../../../src/pages/riddles/seuss.js" /* webpackChunkName: "component---src-pages-riddles-seuss-js" */)
}

